// .headerHome{
//     background-color: white;
//     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
// }
.headerShadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
}
.noShadow{
    box-shadow: none;
    color: black;
}

.headerHome .nav-item .nav-link{
    opacity: 1;
}
.noShadow .nav-item .nav-link ,.noShadow .btnLogin ,.noShadow .userDropdown .nav-link{
    color: rgb(235, 231, 231) !important;
}
.headerHome .nav-item .nav-link.active{
    font-weight: bolder;
}
.imgLogo ,.imgLogoHome{
    height: auto;
    object-fit: cover;
}
.noShadow .imgLogoHome ,.noShadow .imgLanguage ,.noShadow .imgUserLight{
    display: block;
}
.noShadow .imgLogo ,.noShadow .imgLanguageGray ,.noShadow .imgUser,.noShadow .iconMenu{
    display: none;
}
.noShadow{
    box-shadow: none;
}
.headerShadow .imgLogoHome ,.headerShadow .imgLanguage ,.headerShadow .imgUserLight,.headerShadow .iconMenuWhite{
    display: none;
}
.headerShadow .btnLogin,.headerShadow .userDropdown .nav-link{
    color: black;
}
/* .navPlan{
    position: sticky;
    top: 0;
} */
.navbarWebsite .nav-item .nav-link{
    color: white;
    opacity: 0.8;
}
.navPlan .navbar{
    background-color:inherit;
    z-index: 5;
}
footer{
    /* position: absolute; */
    bottom: 0;
}
.dropHome{
    .nav-item{
        .nav-link{
            padding:0 !important;
        }
    }
}
.navbar-toggler:focus{
    box-shadow: none !important;
}
.noShadow .navbar-toggler{
    border: none !important;
}
.headerShadow .navbar-toggler{
    border: none !important;
}