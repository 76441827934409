.websitPages .btnSign{
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(151, 151, 151, 0.25);
    background-color: #fff;
    height: 54px;
    font-size: 14px;
    color: #5b6283;
    font-weight: bold;
    letter-spacing: 0.35px;
    width: 250px;
}
.websitPages .btnSign:hover{
    background-color: rgb(248, 245, 245);
}
.websitPages .btnSignClick{
    font-size: 16px;
}
.websitPages label{
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.35px;
    margin-bottom: 10px;
    color: #8f9bb3;
}
.cardSign .card{
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(151, 151, 151, 0.25);
}
.cardSign .card .form-control{
    font-size: 14px;
    color: black;
    line-height: 1.14;
    padding: 0.5rem 1rem;
}
.cardSign .card .form-control:focus{
    border: solid 1px #0676ed;
    box-shadow: none;
}
.imgLogin{
    width: 455px;
    // height: 75.5vh;
    // max-height: 550px;
    max-height: 100%;
    object-fit: cover;
}
.imgRegister{
    width: 455px;
    // height: 75.5vh;
    // max-height: 710px;
    max-height: 100%;
    min-height: 100%;
    object-fit: cover;
}
.absoluteTextTop{
    position: absolute;
    top: 2rem;
    right: 2rem;
    // letter-spacing: 1.5px;
    font-size: 19px;
    left: 2rem;
}
.absoluteTextBottom{
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    left: 2rem;
}
@media screen and (max-width:991px) {
    .imgLogin ,.imgRegister{
        width: 100%;
    }
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .websitPages .btnSign ,.websitPages .btnSignClick{
        margin-bottom: 1rem;
        width: 100%;
    }
    .imgLogin{
        height: 100%;
        max-height: 100%;
    }
    .imgRegister{
        height: 100%;
        max-height: 100%;
    }
}
@media screen and (max-width:991px) {
    .websitPages .btnSignClick ,.websitPages .linkSign{
        width: 100%;
        text-align: center;
    }
}
@media screen and (max-width:767px) {
    .websitPages .btnSign {
        margin-bottom: 1rem;
        width: 100%;
    }
}