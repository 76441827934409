.bgCard{
    background-color: #8dd7cf;
}
.textBest{
    color: #388a7d;
    font-weight: bold;
    text-transform: uppercase;
}
.list-group .list-group-item .fa-circle{
    color: #b2acfa;
}
.form-switch.form-switch-xl {
    margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}
  
.form-switch.form-switch-xl .form-check-input {
    height: 1.6rem;
    width: calc(2.2rem + 0.75rem);
    border-radius: 5rem;
}
.card .bdgRecommended{
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 25px;
    background-color: #fbe192;
    color: #8d7e61;
}
.card{
    position: inherit !important;
}
.newBadge .badgePrimary{
    background-color: #f3e4ff;
    border-radius: 25px;
    color: #ac7fd0;
    font-size: 14px;
    padding: 0.5rem 1rem;
}
.newBadge .badgeStore{
    background-color: #f8ecab;
    color: #c29a1e;
    border-radius: 25px;
    font-size: 14px;
    padding: 0.5rem 1rem;
}
.newBadge .badgeAnalytics{
    background-color: #dbf6e7;
    color: #51bc58;
    border-radius: 25px;
    font-size: 14px;
    padding: 0.5rem 1rem;
}
.cardBusiness .btnSuccess{
    background-color: #20d577;
    color: white;
}
.cardBusiness .btnSuccess:hover{
    background-color: #0b9950e0;
    color: white;
}
.cardEnterprise .btnDark{
    background-color: #463012;
    color: white;
}
.cardEnterprise .btnDark:hover{
    background-color: #000000;
    color: white;
}
.customDesign .card{
    background-color: #f8eeba;
}
.bgPricing .navbarDashboard{
    background-color: #101b3f;
}
.bgPricing{
    padding: 0rem 0rem 16rem 1rem;
    width: 100%;
    border: none;
    // background: radial-gradient(65% 20% at bottom, transparent 81%, #101b3f 82%);
    background: radial-gradient(153% 158% at 51% -15%, #101b3f 76%, transparent 76%);
}
.bgOpacity{
    background: url(../../../assets/images/bgOpacity.png) no-repeat;
    background-position: right;
    background-size: auto;
}
.newCardTopPlan{
    margin-top: -17rem;
}
.cardStyle .recommended{
    border: 4px solid #3f5ffe;
}
.cardStyle{
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
}
.recommended .recommenedText{
    display: flex;
    justify-content: center;
}
.recommended .recommenedText span{ 
    position: absolute;
    background-color: #3f5ffe;
    padding: 2px 14px 5px 13px;
    border-radius: 4px;
    color: white;
    top: -12px;
    text-align: center;
}
.minHeight{
    min-height: 3rem;
}