.removebtnhover .btn-secondary ,.removebtnhover .btn-secondary:hover,
.removebtnhover .btn-secondary:active .removebtnhover .btn-secondary:focus{
  background-color: unset !important;
  border: 0px;
  width: 100%;
  box-shadow: none !important;
  padding: 0.5rem 0rem;
}
.removebtnhover .dropdown-toggle::after{
    display: none;
}
.modalHead .close{
  border: none;
  padding: 0px;
  background-color: white;
}
.modalHead .close span{
  background-color: white;
  font-size: 25px;
}
.removebtnhover .dropdownWidth .dropdown-menu.show{
  left: -56px !important;
}
.moreOptions .dropdownWidth .dropdown-menu.show{
  position: fixed!important;
}
@media screen and (max-width:767px) {
  .removebtnhover .dropdownWidth .dropdown-menu.show{
    position: absolute !important;
    left: 0px !important;
    right: 0px;
  }
}