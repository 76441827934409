.inlinTableDropdown .dropdown-menu.show{
    display: inline-table;
}
.table-borderless{
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(151, 151, 151, 0.25);
    border-radius: 10px;
}
.textRed{
    color: #db1d1d;
}