.card .progress{
    background-color: white;
    border: none;
}
.card .progress .progress-bar{
    background-color: #3eeb91;
    height: 12px;
}
.copyRight .btn-outline-secondary{
    height: 54px;
    border-radius: 5px !important;
    color: black;
}
.copyRight .btn-outline-secondary:hover{
    background-color: white;
    color: black;
    border: 2px solid #3f5ffe;
}
.copyRight .btn-outline-secondary.active{
    border: 2px solid #3f5ffe;
    color: black;
    background-color: white;
}
.copyRight .btn-outline-secondary span::before{
    content: url('../../../assets/images/circle2.png');
    position: absolute;
    left: 20px;
}
.copyRight .btn-outline-secondary.active span::before{
    content: url('../../../assets/images/circle.png');
    position: absolute;
    left: 20px;
}
.btnType .btn-outline-secondary{
    border: none;
    background-color: #fdfefe;
    position: relative;
    color: black;
}
.btnType .btn-outline-secondary .imgType{
    border: solid 1px #a1afbb;
    height: 133px;
    width: 150px;
    color: #a1afbb;
}
.btnType .btn-outline-secondary .imgType img{
    width: 59px;
    margin-top: 2.4rem;
    height: 57px;
}
.btnType .btn-outline-secondary.active .imgType,.btnType .btn-outline-secondary:hover .imgType{
    border: 1px solid #3f5ffe;
    color: #3f5ffe;
}
.btnType .btn-outline-secondary:hover ,.btnType .btn-outline-secondary.active{
    background-color: white;
    color: #3f5ffe;
    box-shadow: none;
} 
.btnType .btn-outline-secondary:focus{
    box-shadow: none !important;
}
.rotateDegree{
    transform: rotateY(180deg);
}
.btnType .btn-outline-secondary.active::before{
    content: url('../../../assets/images/check.png');
    position: absolute;
    border-radius: 50%;
    top: 10px;
    right: 10px;
}
@media screen and (max-width:450px){
    .btnType .btn-outline-secondary .imgType{
        width: 100px;
        height: 110px;
    }
}