.editProgress .progressBar{
   height: 10px;
}
.progressBar .progress-bar{
   background-color: #1fe37d;
}
.accordionPage .accordion-button:not(.collapsed){
   background-color: white;
   box-shadow: none;
}
.accordionPage .accordion-collapse .accordion-body{
   padding-top: 0.2rem;
}
.btnYellow{
   background-color: #f7d800 !important;
}
.btnYellow:hover{
   background-color: #eece00e0;
}
.btnLight{
   background-color: #D5DCFF !important;
}
.btnLight:hover{
   background-color: #c6cceb !important;
}
.sectionUpload article{
   margin-bottom: 1px;
}
.status-badge{
   background-color: rgba(151, 151, 151, 0.25);
   color: #3e5ae2 !important;
   border-radius: 25px;
   height: 1.5rem;
   font-size: 12px;
   padding: 0.25rem 1rem;
}
.badgeNumber{
   background-color: rgba(151, 151, 151, 0.25);
   color: #3e5ae2 !important;
   padding: 0.54em  0.35em !important;
   width: 25px;
   height: 25px;
}
.badgeCompleted{
   background-color: #dbf6e7;
   color: #10ab48 !important;
   height: 1.5rem;
   font-size: 12px !important;
   padding: 0.33rem 1rem !important;
   border-radius: 25px !important;
}
.badgeWarning{
   background-color: #ffc107;
   color: #fff !important;
   height: 1.5rem;
   padding: 0.33rem 1rem !important;
   border-radius: 25px !important;
}
.badgeDanger{
   background-color: #bf4e4e;
   color: #fff !important;
   height: 25px;
   font-size: 12px;
   padding: 0.4rem 0.8rem !important;
   border-radius: 25px !important;
}
.textGreen{
   color: #00ae38;
}
.minWidthCol{
   width: 180px;
   min-width: 180px;
}
.imagePublish{
   width: 100%;
   height: 130px;
   object-fit: cover;
}