.scroll {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e7e9eb;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e7e9eb;
    border-radius: 10px;
  }
}

.upload-label {
  display: inline-block;
  background-color: #e7e9eb;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

.chat {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  // position: fixed;
  // right: 1rem;
  .right {
    margin-left: auto;
    border-radius: 20px 20px 0 20px;
  }
}

.fixedHeightChat {
  height: 55vh;
  overflow: auto;
}

.boxShadow .widthBtn {
  min-width: 120px;
  border-radius: 25px;
}

.inputGroupChat .form-control {
  border-radius: 20px 20px;
}

.inputGroupChat .input-group-text {
  border-radius: 0px 20px 20px 0px;
}

@media screen and (min-width:992px) and (max-width:1200px) {
  .fixedHeightChat {
    height: 56vh;
  }
}

@media screen and (min-width:1400px) {
  .fixedHeightChat {
    height: 60vh;
  }
}

@media screen and (max-width:992px) {
  .fixedHeightChat {
    height: 300px;
  }
}