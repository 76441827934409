.borderCard .card{
    border-width: 5px 1px;
    border-top-color: rgb(17, 17, 163);
    border-bottom-color: rgb(17, 17, 163);
}
.needs-validation{
    .textPrim{
        color: #101426;
        font-weight: 400;
    }
}