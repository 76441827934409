// .headerGeneral{
//     background-color: white;
//     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
// }
.headerShadowGeneral{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.noShadowGenereal{
    box-shadow: none;
}

.headerGeneral .nav-item .nav-link{
    color:#6D768D !important;
}

.headerGeneral .nav-item .nav-link.active{
    font-weight: bold;
    color: black !important;
    opacity: 1;
}
.imgLogoGeneral{
    height: auto;
    object-fit: cover;
}
/* .navPlan{
    position: sticky;
    top: 0;
} */
.navbarWebsite .nav-item .nav-link{
    color: white;
    opacity: 0.8;
}
.navPlan .navbar{
    background-color:inherit;
    z-index: 5;
}
footer{
    /* position: absolute; */
    bottom: 0;
}