@font-face {
   font-family: "HelveticaNeue";
   src : url(assets/fonts/HelveticaNeue.ttc);
}
@font-face {
   font-family: "HelveticaNeue-med";
   src : url(assets/fonts/HelveticaNeueMedium.ttf);
}
.fw-medium{
   font-family: "HelveticaNeue-med";;
}
body{
   font-family: "HelveticaNeue";
   background-color: #f7f9fa;
}
.textMed{
   color: #585F79;
}
h1,h2,h3,h4,h5,h6,p,span,a,.btn,label,li,th,td,input,button{
   font-family: "HelveticaNeue";
   font-stretch: normal;
   font-style: normal;
}
.font-12 {
   font-size: 12px !important;
}
.font-13 {
   font-size: 13px !important;
}
.font-14 {
   font-size: 14px !important;
}
.font-15 {
   font-size: 15px !important;
}
.font-16 {
   font-size: 16px !important;
}
.font-17 {
   font-size: 17px !important;
}
.font-18 {
   font-size: 18px !important;
}
.font-19 {
   font-size: 19px !important;
}
.font-20 {
   font-size: 20px !important;
}
.font-22 {
   font-size: 22px !important;
}
.font-24 {
   font-size: 24px !important;
}
.height-42{
   height: 42px;
}
.height-45{
   height: 45px;
}
.height-47{
   height: 47px;
}
.height-50{
   height: 50px;
}
.height-62{
   height: 62px;
}
.textBlueDark{
   color:#101B3F;
}
.textVeryDark{
   color: #101426;
}
.bgInfo{
   background: #BEEAF9;
}
.bgLightGreen{
   background-color: #BBFABC;
}
.bgdarkGreen{
   background-color: #F8EEBA;
}
.heightSelect div{
   padding-top: 2.5px;
   padding-bottom: 2.5px;
   // background-color: #FDFEFE;
}
.textSuccess{
   color: #1fe37d;
}
.textLightSuccess{
   color: #22C972;
}
.bgLightSuccess{
   background-color: #22C972;
}
.bgLightPrimary{
   background-color: #3F5FFE;
}
.bgLightSecondary{
   background-color: #DBF6E7;
}
.bgBadgeDanger{
   background-color: #fbe8e8;
   @media screen and (max-width:500px){
      font-size: 12px;
   }
}
.mobileImage{
   @media screen and (max-width:500px){
      margin: auto;
      display:block;
   }
}
.textGeneral{
   color: #07224f;
}
.textHeaders{
   color: #0D1B41;
}
.textWarning{
   color: #F8CC47;
}
.btnFooter .btnRed{
   background-color: #d3455b;
}
.btnFooter .btnRed:hover{
   background-color: #d12841;
   color: white;
}
.btnFooter .btnCancel{
   color: #d3455b;
   border: 2px solid #efbcc4;
}
.btnFooter .btnCancel:hover{
   color: #d3455b;
   background-color: #f0dcdf;
   border: 2px solid #efbcc4;
}
.font-size-12{
   font-size: 12px;
}
.btn-hover{
cursor: pointer;
}
.fw-bold-600{
    font-weight: 600;
}
.pagination{
   padding-top: 0.5rem;
   margin-bottom: 0.5rem;
}
@font-face {
   font-family: "Cairo";
   src : url(assets/fonts/Cairo-Regular.ttf);
}
.ar-wrapper{
   direction: rtl;
   p,h1,h2,h3,h4,h5,h6,button,li,a,span,textarea,label,input,select{
      font-family: 'Cairo', sans-serif;
   }
   .absoluteLeftImage{
      background: url(assets/images/rightW.png) no-repeat;
      background-position:right;
   }
   .text-start {
      text-align: right !important;
  }
  .absoluteText{
   border-radius: 5px 1px 5px 108px;
  }
  .me-auto{
   margin-left: auto;
   margin-right: unset !important;
  }
  .me-2{
   margin-right: unset !important;
   margin-left: 0.5rem;
  }
  .inputGroup ,.inputGroupChat{
   direction: ltr;
  }
  .inputGroup .form-control{
   text-align: end;
  }
  .pe-2 {
      padding-left: 0.5rem !important;
      padding-right: unset !important;
  }
  .justify-content-end{
   justify-content: flex-start !important;
  }
  .fixedChat {
   position: fixed;
   left: 0.4rem;
   width: 340px;
}
.accordion-button::after{
   margin-left: unset;
   margin-right: auto;
}
@media (min-width: 992px){
   .text-lg-end {
       text-align: left !important;
   }
   .me-lg-3 {
       margin-left: 1rem;
       margin-right: unset!important;
   }
}
  @media (min-width: 768px){
     .me-md-3 {
         margin-left: 1rem !important;
         margin-right: unset !important;
     }
  }
}
.btn-primary{
   background-color: #4060FF !important;
}
.btn-primary:hover{
   background-color: #4060FF;
   box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5) !important;
}