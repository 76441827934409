.editProgress .progressBar{
   height: 10px;
}
.progressBar .progress-bar{
   background-color: #1fe37d;
}
.accordionPage .accordion-button:not(.collapsed){
   background-color: white;
   box-shadow: none;
}
.accordionPage .accordion-collapse .accordion-body{
   padding-top: 0.2rem;
}
.btnYellow{
   background-color: #f7d800 !important;
}
.btnYellow:hover{
   background-color: #eece00e0;
}
.btnLight{
   background-color: #D5DCFF !important;
}
.btnLight:hover{
   background-color: #c6cceb !important;
}
.sectionUpload article{
   margin-bottom: 1px;
}
.status-badge{
   background-color: rgba(151, 151, 151, 0.25);
   color: #3e5ae2 !important;
   border-radius: 25px;
   height: 1.5rem;
   font-size: 12px;
   padding: 0.25rem 1rem;
}
.badgeNumber{
   background-color: rgba(151, 151, 151, 0.25);
   color: #3e5ae2 !important;
   padding: 0.54em  0.35em !important;
   width: 25px;
   height: 25px;
}
.badgeCompleted{
   background-color: #dbf6e7;
   color: #10ab48 !important;
   height: 1.5rem;
   font-size: 12px !important;
   padding: 0.33rem 1rem !important;
   border-radius: 25px !important;
}
.badgeWarning{
   background-color: #ffc107;
   color: #fff !important;
   height: 1.5rem;
   font-size: 12px !important;
   padding: 0.33rem 1rem !important;
   border-radius: 25px !important;
}
.badgeDanger{
   background-color: #bf4e4e;
   color: #fff !important;
   height: 25px;
   font-size: 12px;
   padding: 0.4rem 0.8rem !important;
   border-radius: 25px !important;
}
.textGreen{
   color: #00ae38;
}
.textLight{
   color: #A1A2A2;;
}
.textSecondary{
   color: #5E5E5E;
   font-weight: 400;
}
.minWidthCol{
   width: 180px;
   min-width: 180px;
}
.imageDesign{
   width: 100%;
   height: 130px;
   object-fit: cover;
}
.width-75{
   width: 75%;
   @media screen and (max-width:500px){
      width: 100%;
   }
}
.imageFinish{
   width: 100%;
   height: 160px;
   object-fit: cover;
}
.firstStep{
   .card-body {
      padding: 2rem;
   }
   .title{
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 20px;
   }
   .subTitle{
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
   }
   .header{
      padding-bottom: 1rem;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 1rem;
   }
   .formNum{
      font-weight: 600;
   }
   .border-div{
      padding-bottom: 50px;
      margin-bottom: 30px;
      border-bottom: 1px solid #dee2e6;
   }
}
.contentPage .card{
   border-radius: 8px;
   border: 1px solid rgba(151, 151, 151, 0.25);
   background: #FFF;
   box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
}
.session button,
.session2 button{
   background: var(--bs-primary);
   color: #fff;
   border: none;
   border-radius: 5px;
   padding: 9px 18px;
}
.session2{width: 50%;}
.session2 button{
   width: 100%;
   border-radius: 8px;
   border: 1px solid var(--bs-primary);
   background: none;
   color: var(--bs-primary);
}
.minHeightLabel{
   min-height: 4rem;
}