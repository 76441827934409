.imageTemplate {
  width: 200px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.styleCard .card {
  border-top: solid 1px rgba(151, 151, 151, 0.25);
  border-right: solid 1px rgba(151, 151, 151, 0.25);
  border-left: solid 1px rgba(151, 151, 151, 0.25);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.template-border {
  //  border-bottom: solid 1px rgba(151, 151, 151, 0.25);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
}

.bg-template {
  background: #d9f4ef;
}
.sectionUpload img{
  height: 112px;
  object-fit: cover;
}
.btnWidth{
  width: 144px;
  text-align: right;
}
@media screen and (max-width:767px) {
  .imageTemplate {
    width: 100%;
    height: 150px;
  }
  .btnWidth ,.bgGray .btnSearch{
    width: 100%;
  }
}
.badgeDanger{
  background-color: #ff7d7d94;
  color: #bf4e4e!important;
}