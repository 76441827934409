.absoluteEnterprise{
    position: absolute;
    top: -4rem;
    right: 1rem;
    left: 1rem;
}
.card .btnSuccess{
    background-color: #20d577;
    color: white;
}
.card .btnSuccess:hover{
    background-color: #0b9950e0;
    color: white;
}
.saveDiscount{
    bottom: -17px;
}
.saveDiscount .badge{
    padding-top: .7rem;
    padding-bottom: .7rem;
}