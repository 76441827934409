.editProgress .progressBar{
   height: 10px;
}
.progressBar .progress-bar{
   background-color: #1fe37d;
}
.accordionPage .accordion-button:not(.collapsed){
   background-color: white;
   box-shadow: none;
}
.accordionPage .accordion-collapse .accordion-body{
   padding-top: 0.2rem;
}
.card .btnYellow{
   background-color: #f7d800;
}
.btnYellow:hover{
   background-color: #eece00e0;
}

.status-badge{
    background-color: #0b5ed7;
    border-radius: 25px;
    color: white;
    font-size: 14px;
    padding: 0.5rem 1rem;
}
.fixedChat{
   position: fixed;
   right: 0.4rem;
   width: 340px;
   // z-index: 555555;
}
@media screen and (max-width:991px){
   .fixedChat{
      position: relative;
      right: 0;
      width: 100%;
   }
}
.newChat{
   top: 90px !important;
}