.cardLEarn{
    border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
}
.imgLearning{
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}
.imageSameHeight{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}
.latestArticle{
    background: url(../../../assets/images/rightSide.png) no-repeat;
    background-position: right;
}
.newTabs .nav-item .nav-link{
    border: none;
    color: #0d326c;
    cursor: pointer;
}
.newTabs .nav-item .nav-link.active{
    border-bottom: 2px solid #0d326c;
    padding-bottom: 0px;
    font-weight: bold;
}
.blogPage .absoluteLeftImage{
    background-position: left bottom;
}
// ===========================================
.contentBlog{
    background-color: #0d326c;
}
.marginImage{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
}
.margin_section{
    margin-bottom: -4rem;

}
.backgroundSquare{
    background: url(../../../assets/images/triangle2.png) no-repeat;
    background-position: right center;
}
.img-blog{
    height: 250px;
    width: 100%;
    object-fit: cover
}