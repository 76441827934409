.imageTemplate {
  width: 168px!important;
  max-height: 160px;
  height: max-content!important;
  object-fit: cover;
  border-radius: 5px;
}

.styleCard .card {
  border-top: solid 1px rgba(151, 151, 151, 0.25);
  border-right: solid 1px rgba(151, 151, 151, 0.25);
  border-left: solid 1px rgba(151, 151, 151, 0.25);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.template-border {
  //  border-bottom: solid 1px rgba(151, 151, 151, 0.25);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
}

.bg-template {
  background: #d9f4ef;
}
.sectionUpload img{
  height: 112px;
  object-fit: cover;
}
.btnWidth{
  width: 144px;
  text-align: right;
}
.keywordsDesign .text-primary{
  min-width: max-content;
}
::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(226, 222, 222); 
  border-radius: 0px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209); 
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adabab; 
}
@media screen and (max-width:767px) {
  .imageTemplate {
    width: 100%;
    height: 150px;
  }
  .btnWidth ,.bgGray .btnSearch{
    width: 100%;
  }
}