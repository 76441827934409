

.scroll{
    ::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px  #e7e9eb; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #e7e9eb; 
        border-radius: 10px;
      }
}
.upload-label{
    display: inline-block;
    background-color: #0d6efd; 
    color: white;
    height: 35px;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  .chat{
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);

  }