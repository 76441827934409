.needs-validation .form-control{
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(151, 151, 151, 0.25);
    font-size: 14px;
}
.needs-validation .form-control:focus{
    border: solid 1px #0676ed;
    box-shadow: none;
}
.needs-validation label{
    color: rgb(134 131 131);
    padding-bottom: 0.1rem;
    margin-bottom: 2px;
    font-size: 14px;
}
.sectionUpload button{
    width: 100%;
}
.dropdownWidth .dropdown-item:active ,.bgWhite .dropdown-item:active{
    background-color: white !important;
}
.bgGray .btnSearch{
    background-color: white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #3f5ffe;
    color: #0b5ed7;
    width: 144px;
    // font-weight: bold;
}
.bgGray .btnSearch:hover{
    border: 2px solid #0b5ed7;
    color: white;
    background-color: #0b5ed7;
}
.iconSearch{
    border-radius: 5px;
    height: 50px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border-top: solid 1px rgba(151, 151, 151, 0.25);
    border-bottom: solid 1px rgba(151, 151, 151, 0.25);
    border-left: solid 1px rgba(151, 151, 151, 0.25);
    border-right: 0;
    background-color: white;
}
.iconSearch .fa{
    padding: 0.5rem 1rem;
    line-height: 1rem;
}
.inputGroup .form-control:focus .iconSearch{
    border-color: red;
}
.boxShadow{
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px rgba(151, 151, 151, 0.25);
}
.dNoneBtn .btnHide{
    display: none;
}
.hideBtnAdd .btn-primary{
    display: none;
}
@media screen and (max-width:767px) {
    .bgGray .btnSearch{
        width: 100%;
    }
}
.accountManagerSelect{
    .css-26l3qy-menu div{
        color: black;
    }
    .css-26l3qy-menu div:nth-of-type(1){
        color: red;
    }
    .css-26l3qy-menu div:nth-of-type(1):checked{
        color: #fff;
    }
}
