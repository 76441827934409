.bgGray,body{
    background-color: rgba(247, 249, 251, 0.5) !important;
}
.height100{
    min-height: 100vh;
}
.imgPassword{
    margin-top: 0.6rem;
    width: 30px;
}
.card-body .input-group .input-group-append{
    border:1px solid #ced4da !important;
    border-left: 0 !important;
    padding-right: 0.2rem;
    border-radius:0px 5px 5px 0px ;
}
.height50{
    height: 50px;
}
.height40{
    height: 40px;
}
.input-addon {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 38px;
    top: 40px;
    right: 4px;
    &:hover {
      cursor: pointer;
    }
}
.loaderStyle{
    position: fixed;
    left: 48%;
    top: 42%;
}