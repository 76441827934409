.textPrimary{
    color: #3B4967;
}
.textDarkPrimary{
    color: #111C40;
}
.lineHeight{
    line-height: 60px;
}
.description{
    font-size: 18px;
    @media screen and (max-width:500px){
        font-size: 14px;
    }
}
.homepage{
    background-color: #fafbfc;
}
// .homepage .btn-primary{
// }
.homepage .btn-primary:hover{
    background-color: #4060FF;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
// .card .minHeightCard{
//     min-height: 12rem;
//     @media screen and (max-width:500px){
//         min-height: auto;
//     }
// }
.lunchWebsite{
    background-color: #111C40;
    border-radius: 20px;
}
.btnGroup button{
    padding: 1rem;
    margin: 1rem;
    min-height: 8rem;
    border-radius: 4px !important;
}
.btnGroup button:first-child{
    margin-left: 0px;
}
.btnHeightLg{
    height: 50px;
}
.headerHome .nav-item .nav-link{
    color: #0d1b41;
    opacity: 0.6;
}
.firstSection1{
    background: url(../../../assets/images/home1.png) no-repeat;
    background-position: center bottom;
    background-size: cover;
    @media screen and (max-width:500px){
        background-position: bottom center;
    }
}
.firstSection2{
    background: url(../../../assets/images/home1.png) no-repeat;
    background-position: bottom;
    @media screen and (max-width:800px) {
        background: none;
    }
}
.starSection{
    background-color: #cde0e6;
    border-radius: 10px;
    box-shadow: 0 2px 12px 2px rgba(13, 20, 22, 0.05);
}
.imgActivities{
    width: 46px;
    height: 46px;
    border-radius: 10px;
}
.cardSupport{
    border-radius: 12px !important;
    background: #FFF;
    border: none;
    // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.18);
}
.imgSupport{
    width: 80px;
    height: 80px;
    margin-top: -4.7rem;
}
.imgMark{
    right: 0;
    bottom: 0;
    @media screen and (max-width:500px){
        width: 90px;
    }
}
.leftAbsoluteImage{
    position: absolute;
    left: 0;
}
.imgTemplate{
    width: 156px;
    height: 105px;
    object-fit: cover;
}
.clearBoth{
    clear: both;
}
.time-image{
    width: 100%;
    height: 1172px;
}
// .bgTimeline{
//     background: url(../../../assets/images/timeline.png) no-repeat;
//     background-position: top;
//     @media screen and (min-width:1400px){
//         background-size: cover;
//         padding-bottom: 4rem;
//     }
//     @media screen and (max-width:500px){
//         background: url(../../../assets/images/timelineMob.png) no-repeat;
//         background-size: cover;
//         height: auto;
//     }
// }

.absoluteText{
    width: 60px;
    border-radius: 5px 1px 108px 8px;
    height: 53px;
    background-color: #e2f1f8;
    span{
        padding-left: 1.2rem;
        font-size: 32px;
    }
}
.rocketImageAbsolute{
    position: absolute;
    top: -3rem;
    right: 0;
}
.bgLunch{
    background: url(../../../assets/images/green.png) no-repeat;
    background-size: cover;
    @media screen and (max-width:500px){
        background-position: top center;
        margin-top: 2rem;
    }
}
.absoluteCircle{
    position: absolute;
    left: 20px;
    top: 0;
    margin-top: -2.5rem;
    @media screen and (max-width:500px){
        width: 40px;
        margin-top: -1.5rem;
    }
}
.footerHome{
    background-color: #101b3f;
}
.imgPills{
    @media screen and (max-width:500px){
        width: 40px;
    }
}
.headerTimeline{
    font-size: 20px;
    @media screen and (max-width:500px){
        font-size: 14px;
    }
}
.descTimeline{
    font-size: 14px;
    @media screen and (max-width:500px){
        font-size: 12px;
    }
}
@media screen and (max-width:768px){
    .position-time{
        padding-left: 5%;
    }
}
.textHeaders{
    font-size: 18px;
}
.askQues .card{
    border-radius: 20px;
}
@media screen and (max-width:500px){
    .minHeightDesc{
        min-height: 72px;
    }  
    .lineHeight{
        margin-top: 40px;
        line-height: 30px;
        font-size: 22px;
        padding-right:50px;
        padding-left:50px;
        font-weight: 400;
    }
    .description{
        padding-left: 20px;
        padding-right: 20px;
    }
    .about-section .font-16{
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .about-section .imgActivities{
        width: 24px;
        height: 24px;
        border-radius: 4px;
    }
    .section-website{
        padding-left: 15px;
        padding-right: 15px;
    }
    .section-website .textMed{
        font-size: 14px;
    }
    .cards{
        padding-left: 30px;
        padding-right: 30px;
    }
    .cards h4{
        font-size: 22px;
    }
    .cards .textMed ,.cards h6{
        font-size: 14px;
    }
    .cards .imgSupport{
        width: 60px;
        height: 60px;
        margin-top: -4rem;
    }
    .time-image{
        height: 542px;
        max-width: 93%;
        right: 16px;
        left: 16px;
    }
    .hideArrow{
        display: none;
    }
    .textHeaders{
        font-size: 16px;
    }
    .smallText{
        font-size: 14px;
    }
    .textLarge{
        font-size: 22px;
    }
    .textEqual{
        font-size: 16px;
        padding-left:1.8rem;
        padding-right:1.8rem;
    }
}
.imgTime{
    width: 80px;
    height: 80px;
    object-fit: cover;
    @media screen and (max-width:500px){
        width: 30px;
        height: 30px;
    }
}
.angle-45{
    transform: rotate(45deg);
}
.itemSlider{
    width: 100%;
    height: 471px; /* Set the height of the container */
    overflow: hidden;
    position: relative;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #00000029;
    padding-bottom: 1rem;
    padding: 1rem;
    @media screen and (max-width:500px){
        height: 200px;
    }
}
.imgSlider{
    width: 98%;
    position: absolute;
    border-radius: 15px;
    top: 2%;
    left: 1%;
    bottom: 1%;
    transform: translate(-0%, -0%);
    @media screen and (max-width:500px){
        height: 200px;
        object-fit: cover;
    }
}
.indecators .carousel-control-next{
    position: absolute;
    opacity: 1;
    width: 30px;
}
.indecators .carousel-control-prev{
    // width: 100px;
    position: absolute;
    right: 80px;
    left: unset;
    opacity: 1;
    width: 30px;
}
.indecators .carousel-control-prev .carousel-control-prev-icon,.indecators .carousel-control-next .carousel-control-next-icon{
    background-color: #3F5FFE;
    border-radius: 12px;
}
.indecators{
    margin-top: 12px;
    height: 100px;
    z-index: 90;
}