.disclaimer{
    background: rgba(63, 95, 254, 0.20);
    border-radius: 20px;
    padding: 60px;
    @media screen and (max-width:500px){
        padding: 20px;
    }
}
.define{
    color: #3F5FFE;
}