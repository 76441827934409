.overlaySection .preview-btn{
  top:30%;
  left:5%;
  position: absolute;
  width: 90%;
  text-align: center;
}
.overlaySection{
  display: none;
}
.cardShadow{
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(151, 151, 151, 0.25) !important;
}
.position-relative .cardPreview{
  height: 500px;
  border: none;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.cardpreview:hover .overlaySection .preview-btn{
  display: block !important
}
.preview-btn:hover {
  display: block !important
}
.cardPreview:hover .overlaySection ,.selected_template .overlaySection{
  background-color: rgba(0, 0, 0, 0.661);
  display: block;
  width: 100%;
  height: 440px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 6px;
}
.cardPreview:hover .headerTemplate{
  display: none;
  padding: 0;
}
.cardPreview:hover{
  height: 440px;
}
.maxDescription{
  height: 3rem;
  overflow: hidden;
}
.img-preview{
  height: 440px;
  width: 100%;
  object-fit: cover;
  object-position: 0px 0%;
  border-radius: 6px;
}
.cardPreview:hover .img-preview{
  filter: blur(2px);
}
.descriptionTemplate{
  max-height: 300px;
  overflow: hidden;
}
// .img-preview-custom {
//     height: 130px;
//     width: 90% !important;
//     object-fit: cover;
// }
.custom-card{
    background: #f8ecab !important;
    height: 500px;
    overflow: hidden;
}

.selected_template::before{
    content: url('../../../assets/images/check2.png');
    position: absolute;
    border-radius: 50%;
    top: -14px;
    right: 0px;
    z-index: 1000;
}

.selected_border {
    border: 2px solid blue;
}
// .pic {
//     width: 100%;
//     height: 200px;
//     overflow: hidden;
//     margin: 0 auto;
//     display: inline-block;
//     position: relative;
//     cursor: pointer;
//   }
  
//   .pic:before {
//     width: 100%;
//     height: 200px;
//     background: rgba(0, 0, 0, 0.5);
//     position: absolute;
//     top: 0;
//   }
  
//   .pic:after {
//     color: #fff;
//     font-size: 18px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin: -20px -25px;
//     border: 1px solid rgba(255, 255, 255, 0.5);
//     padding: 10px;
//   }
  
//   .pic img {
//     max-width: 100%;
//     width: 100%;
//     cursor: pointer;
//   }
  
//   .pic:hover img {
//     animation: moveSlideshow 4s linear;animation-fill-mode: forwards;
//   }
  
//   @keyframes moveSlideshow {
//     100% {
//       transform: translateY(calc(-100% + 200px));
//     }
//   }
  
//   .pic:hover .pic:after {
//     opacity: 0;
//   }
.btnWidthBuy{
  width: 70%;
  @media screen  and (max-width:500px){
    width: 100%;
  }
}
.heightSection{
  min-height: 176px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.customImage{
  max-height: 240px;
  object-fit: cover;
}
.btnSchedule{
  bottom: 10px;
  right: 14px;
  left: 14px;
}