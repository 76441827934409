.card-step{
    .card-identity{
        background-color: #111C40;
        border-radius: 14px;
        color: #ffffff;
    }
    .bg-group{
        background-color: #586079;
    }
    .card-example{
        border-radius: 14px;
        margin-top: -2rem;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        .exampleImg{
            margin-top: -2.2rem;
        }
    }
}