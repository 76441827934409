.page-container {
    text-align: right;
    padding: 10px 20px;
    .page {
        height: 32px;
        width: 32px;
        line-height: 28px;
        font-size: 12px;
        text-align: center;
        border-radius: 16px;
        background-color: white;
        display: inline-block;
        margin-left: 10px;
        color: rgb(17, 88, 243);
        font-weight: 600;
        border: solid 2px rgb(17, 88, 243);
        transition: all 0.2s ease-in-out;
        &:hover {
            cursor: pointer;
            color: white;
            background: rgb(17, 88, 243);
            border: solid 2px rgb(17, 88, 243);
            transform: translateY(-4px);
        }
    }
    .page.active {
        cursor: pointer;
        color: white;
        background: rgb(17, 88, 243);
        border: solid 2px rgb(17, 88, 243);
    }
}
.newLink{
    cursor: pointer;
}